$background-color: rgb(16, 37, 63);
$manu-color: rgb(194, 191, 191);

$primary-color: #282c34;
$font-color: rgb(252, 252, 252);
$secondary-font-color: rgb(170, 170, 170);
$secondary-color: rgb(38, 161, 238);
$hover: rgb(102, 185, 236);
$dark-font: rgb(121, 121, 121);
$currentRoute: rgb(8, 163, 235);

$euro-blue: rgb(18, 119, 213);
