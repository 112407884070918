/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed", sans-serif;
  /* font-weight: 600; */
  /* color: rgb(216, 216, 216) !important; */
  letter-spacing: 1px !important;
}

body {
  overflow-y: scroll;
  background-color: black !important;
}

textarea {
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  background-color: #ededed;
  border-width: 0 0 1px 0;
  border-color: grey;
  border-radius: 4px 4px 0 0;
  padding: 0.5rem;
}

/* Image upload */
.fileContainer {
  border-radius: 0;
  width: fit-content;
  padding: 1rem;
}

.chooseFileButton {
  border-radius: 0 !important;
}

.uploadPictureContainer {
  padding: 5px !important;
  margin: 0.5rem !important;
}

.bold {
  font-weight: bold;
}

.bar {
  border-radius: 5px 5px 0 0 !important;
}

.progress {
  border-radius: 5px 5px 0 0 !important;
}
