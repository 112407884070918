.container {
  padding-top: 120px;
  margin: 0 20px;
}

.tabContainer {
  max-width: 1000px;
  margin: auto;
  color: black !important;
  background-color: black;
}
