.footerContainer {
  margin: 20px;
  padding: 20px;
  color: rgb(216, 216, 216);
}
.footer {
  margin: auto;
  text-align: center;
}
.footerText1 {
  margin: 0 30px;
}
.addressContainer {
  margin: 20px;
}
