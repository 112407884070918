@import "styles/main.scss";

.grid {
  margin: auto;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(4, 350px);
  grid-gap: 10px;
  padding: 10px 0;
  @media only screen and (max-width: 1450px) {
    grid-template-columns: repeat(3, 350px);
  }
  @media only screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 350px);
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
  }
}

.griditem {
  height: max-content;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
}

.griditem-img-container {
  overflow: hidden;
  height: 300px;
}

.griditem-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.griditem-hoverinfo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.25s linear;
  width: 350px;
  background-color: $euro-blue;
  height: 300px;
  padding: 10px;
  font-weight: bold;
  overflow: hidden;
}

.griditem-info {
  background-color: $euro-blue;

  font-weight: bold;
  padding: 5px 10px;
}

.griditem:hover .griditem-hoverinfo {
  visibility: visible;
  opacity: 0.85;
}

.griditem-info1 {
  font-size: 18px;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.griditem-info2 {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
