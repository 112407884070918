.projectsContainer {
}

.itemContainer {
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: center;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
  @media (max-width: 853px) {
    grid-template-columns: 1fr;
  }
}

.itemName {
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 853px) {
    width: 100%;
    text-align: center;
  }
}

.showHide {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  @media (max-width: 853px) {
    width: auto;
    text-align: center;
  }
}

.container {
  padding-top: 120px;
}

.uploadContainer {
  margin: 20px 30px 0;
  text-align: center;
}

.fileName {
  margin-bottom: 4px;
}

.fileName:last-child {
  margin-bottom: 20px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortImg {
  object-fit: cover;
  height: 70px;
  width: 120px;
}

.deletebtn {
  color: red;
  margin-left: 5px;
  font-size: 20px;
}

.separator {
  width: 100%;
  height: 4px;
  background-color: black;
}
