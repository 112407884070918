.inputLead {
  display: flex;
  align-content: center;
  font-size: 16px;
  margin: 13px auto 5px 5px;
  color: white;
}

.input {
  width: 100%;
}

.infoLead {
  font-size: 16px;
  margin: 30px 0;
  color: white;
}
.confirmation1 {
  font-size: 20px;
  text-align: center;
  color: white;
}

.confirmation2 {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  color: white;
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: 5px 0;
  }
}

.formRow1 {
  flex-basis: 100%;
}

.formRow2 {
  flex-basis: 50%;
}

.formRow3 {
  flex: 0 0 33.333333%;
}

.radioContainer {
  margin: 0 30px 0;
  display: flex;
}

.radioItem {
  display: flex;
  margin-right: 20px;
  > label {
    margin-left: 5px;
    color: white !important;
  }
}

.textArea {
  margin: 10px 5px 0;
  box-sizing: border-box;
}
