.container {
  padding-top: 120px;
}

.uploadContainer {
  margin: auto 30px;
  text-align: center;
}

.fileName {
  margin-bottom: 4px;
}

.fileName:last-child {
  margin-bottom: 20px;
}
